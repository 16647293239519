<div class="page-notfound">
  <img
    src="assets/images/FarmPortal_logo.svg"
    class="mx-auto"
    alt="FarmPortal logo" />
  <h4 class="">
    <strong>{{ 'APPLICATION.notFoundText' | translate }}</strong>
  </h4>
  <div class="d-flex justify-content-center gap-16">
    <button
      class="btn btn-secondary"
      (click)="navigateBack()">
      {{ 'APPLICATION.notFoundBackButton' | translate }}
    </button>
    <button
      class="btn btn-primary"
      (click)="navigateMainPage()">
      {{ 'APPLICATION.mainPage' | translate }}
    </button>
  </div>
</div>
