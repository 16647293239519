<div class="form-group">
  <input
    type="range"
    class="form-control"
    [formControl]="inputControl"
    [id]="labelTranslateKey"
    [name]="labelTranslateKey"
    [ngClass]="{ 'is-invalid': isInputControlInvalid() }"
    [min]="min"
    [max]="max"
    [step]="step" />
  <label
    *ngIf="labelTranslateKey.length > 0"
    class="label"
    [ngClass]="{ required: isInputControlRequired() }"
    [for]="labelTranslateKey">
    {{ labelTranslateKey | translate }}
  </label>
  <label
    *ngIf="customLabel && labelTranslateKey.length === 0"
    class="label"
    [ngClass]="{ required: isInputControlRequired() }"
    [for]="customLabel">
    {{ customLabel }}
  </label>
  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
