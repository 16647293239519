import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { LoggerService } from 'farmcloud-core';
import { Observable } from 'rxjs';
import { RoutePermissionsDto } from './models/route-permissions.dto';
import { AuthStoreService } from './services/auth-store.service';
import { UserContextService } from './user-context.service';

@Injectable()
export class IdpOrganizationGuard implements CanActivateChild, CanActivate {
  constructor(
    private router: Router,
    private authStore: AuthStoreService,
    private logger: LoggerService,
    private userContextService: UserContextService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.getOrganizationGuardResult(route, state);
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    return this.getOrganizationGuardResult(childRoute, state);
  }

  private getOrganizationGuardResult(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree {
    const requiredPermission = route.data as RoutePermissionsDto;

    if (this.userContextService.currentOrganizationId != null) {
      // check access if user context is populated...
      return this.checkPermissions(requiredPermission);
    } else {
      // ...else redirect to `load-user-data` which attempts to
      // fetch user context data, provided that user is logged in
      return this.router.createUrlTree(['/load-user-data'], {
        queryParams: {
          returnUrl: state.url
        }
      });
    }
  }

  private checkPermissions(requiredPermission: RoutePermissionsDto): boolean | UrlTree {
    if (!requiredPermission.componentKey) {
      // if there are no requirements defined
      // assume that the route can be freely accessed
      return true;
    }

    const userClaims = this.authStore.getUserClaims;

    if (!userClaims || userClaims.length == 0) {
      const message = '[IdpOrganizationGuard] No groups defined in user claims.';
      this.logger.trackException(new Error(message));
      return false;
    }

    const hasPermission = requiredPermission.permissions.some(c => {
      const fullPermission = requiredPermission.componentKey + '.' + c;
      return userClaims.some(c => c === fullPermission);
    });

    return hasPermission ? true : this.router.createUrlTree(['/']);
  }
}
