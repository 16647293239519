<div class="form-group">
  <label
    *ngIf="labelTranslateKey.length > 0"
    class="label"
    [ngClass]="{ required: isInputControlRequired() }"
    [for]="labelTranslateKey">
    {{ labelTranslateKey | translate }}
  </label>
  <input
    type="number"
    class="form-control"
    [readOnly]="readonly"
    [placeholder]="
      placeholderTranslateKey
        ? (placeholderTranslateKey | translate)
        : ('APPLICATION.FORM.enterPlaceholderText' | translate: { placeholder: labelTranslateKey | translate | lowercase })
    "
    [formControl]="inputControl"
    [id]="labelTranslateKey"
    [name]="labelTranslateKey"
    [ngClass]="{ 'is-invalid': isInputControlInvalid() }"
    [min]="min"
    [max]="max"
    [step]="step"
    [appOnlyDecimal]="allowDecimals"
    [appOnlyPositive]="onlyPositives" />
  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
