<div
  class="toggle-button-container btn-group-toggle"
  data-toggle="buttons">
  <ng-container *ngFor="let option of options">
    <label
      class="toggle-button-item btn"
      [ngClass]="{ active: isOptionActive(option.value) }">
      <input
        type="radio"
        [id]="option.value"
        [name]="radioButtonGroupName"
        [value]="option.value"
        [formControl]="inputControl"
        [checked]="isOptionActive(option.value)" />
      <div class="toggle-button-icon">
        <i [classList]="option.icon"></i>
      </div>
      <div class="toggle-button-label">
        {{ option.label | translate }}
      </div>
    </label>
  </ng-container>
  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
