<div class="d-flex">
  <div class="input-group mb-0">
    <input
      type="text"
      class="form-control"
      [placeholder]="placeholder"
      [ngModel]="date"
      (ngModelChange)="onDateChange($event)"
      [id]="labelTranslateKey"
      [name]="labelTranslateKey"
      [maxDate]="maxDateStruct"
      autocomplete="false"
      [ngClass]="{ 'is-invalid': isInvalid }"
      ngbDatepicker
      [disabled]="isDisabled"
      #d1="ngbDatepicker" />
    <aside class="input-group-append">
      <button
        type="button"
        [disabled]="isDisabled"
        class="btn btn-secondary datepicker"
        (click)="d1.toggle()">
        <i class="ph ph-calendar-blank"></i>
      </button>
    </aside>
  </div>
  <ngb-timepicker
    *ngIf="allowSetTime"
    [ngClass]="{ 'is-invalid': isInvalid }"
    [(ngModel)]="time"
    (ngModelChange)="onTimeChange($event)"
    [spinners]="false"
    [disabled]="isDisabled"
    class="ml-2"></ngb-timepicker>
</div>
