<div class="form-group">
  <label
    *ngIf="labelTranslateKey.length > 0"
    class="label"
    [ngClass]="{ required: isInputControlRequired() }"
    [for]="labelTranslateKey">
    {{ labelTranslateKey | translate }}
  </label>
  <input
    type="text"
    class="form-control"
    placeholder="{{ 'APPLICATION.FORM.enterPlaceholderText' | translate: { placeholder: labelTranslateKey | translate | lowercase } }}"
    [formControl]="phoneNoControl"
    [id]="labelTranslateKey"
    [name]="labelTranslateKey"
    [ngClass]="{ 'is-invalid': isInputControlInvalid() }"
    [textMask]="{ mask: phoneNoMask }" />
  <app-validation-message
    [field]="phoneNoControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
