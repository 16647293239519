import { Component, Input } from '@angular/core';
import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-datetime-picker',
  templateUrl: './input-datetime-picker.component.html',
  styleUrls: ['./../input.base.scss']
})
export class InputDateTimePickerComponent extends InputBaseComponent {
  @Input() maxDate: Date | null;
  @Input() allowsSetTime = false;
}
