<div class="form-group">
  <label
    *ngIf="labelTranslateKey.length > 0"
    class="label"
    [ngClass]="{ required: isInputControlRequired() }"
    [for]="labelTranslateKey">
    {{ labelTranslateKey | translate }}
    <i
      *ngIf="tooltipTranslateKey"
      class="ph ph-info icon-size-16"
      placement="auto"
      ngbTooltip="{{ tooltipTranslateKey | translate }}"></i>
  </label>
  <input
    type="text"
    class="form-control"
    placeholder="{{ 'APPLICATION.FORM.enterPlaceholderText' | translate: { placeholder: labelTranslateKey | translate | lowercase } }}"
    [formControl]="inputControl"
    [id]="labelTranslateKey"
    [name]="labelTranslateKey"
    [ngClass]="{ 'is-invalid': isInputControlInvalid() }"
    [textMask]="textMaskPattern ? { mask: textMaskPattern } : { mask: false }"
    [readonly]="IsReadOnly" />
  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
