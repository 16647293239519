import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UserContextService } from '@farm-portal/core/auth/user-context.service';
import { MessageCoreServicesModule } from '@farm-portal/core/message/message-core-services.module';
import { MessageApiListResponse, MessageDto } from '@farm-portal/core/message/models/message.dto';
import { MessageCoreProxyService } from '@farm-portal/core/message/services/message-core-proxy.service';
import { EmptyStateDisplayModel } from '@farm-portal/shared/modules/layout/empty-state/empty-state-display.model';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigurationService, NotificationType, SpinnerService } from 'farmcloud-core';
import { BehaviorSubject, combineLatest, Observable, timer } from 'rxjs';
import { filter, finalize, map, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: MessageCoreServicesModule
})
export class NotificationService {
  public unreadMessageCount$: Observable<string>;
  public messages$: Observable<MessageDto[]>;
  public emptyStateDisplayModel: EmptyStateDisplayModel = {
    initialDataLoading: false,
    filtersExists: false,
    anyDataReturned: false
  };

  private messageData$: Observable<MessageApiListResponse>;
  private readonly refresh$ = new BehaviorSubject<void>(null);

  constructor(
    private readonly messageCoreProxyService: MessageCoreProxyService,
    private readonly config: AppConfigurationService,
    private readonly router: Router,
    private readonly spinnerService: SpinnerService,
    private readonly translateService: TranslateService,
    private readonly userContextService: UserContextService
  ) {
    if (!this.config.configuration.messages_enabled) {
      return;
    }

    const langChange$ = this.translateService.onLangChange.pipe(
      map(x => {
        return x.lang;
      }),
      startWith(this.translateService.currentLang)
    );

    this.messageData$ = combineLatest([
      timer(0, this.config.configuration.messages_refresh_rate),
      this.refresh$,
      langChange$,
      this.userContextService.currentUserOrganization$
    ]).pipe(
      filter(([, , , currentOrganization]) => !!currentOrganization),
      switchMap(([, , lang]) => {
        return this.messageCoreProxyService.getAllNotifications(lang);
      }),
      shareReplay(1)
    );

    this.messages$ = this.messageData$.pipe(
      map(x => x.items),
      tap(x => {
        this.emptyStateDisplayModel = {
          anyDataReturned: x.length > 0,
          filtersExists: false,
          initialDataLoading: false
        };
      })
    );

    this.unreadMessageCount$ = this.messageData$.pipe(
      map(x => x.items.filter(x => !x.isRead).length),
      map(x => {
        if (x > 9) {
          return '9+';
        }

        return x.toString();
      })
    );
  }

  public markAsRead(notification: MessageDto) {
    if (notification.isRead) {
      return this.navigateToNotification(notification);
    }

    this.spinnerService.display();
    this.messageCoreProxyService
      .markAsRead(notification.id)
      .pipe(
        take(1),
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(() => {
        this.refresh$.next(null);
        return this.navigateToNotification(notification);
      });
  }

  public markAllAsRead() {
    this.spinnerService.display();
    this.messageCoreProxyService
      .markAllAsRead()
      .pipe(
        take(1),
        finalize(() => {
          this.spinnerService.hide();
        })
      )
      .subscribe(() => {
        this.refresh$.next(null);
      });
  }

  private navigateToNotification(notification: MessageDto) {
    if (!notification.notificationEntityId) {
      return;
    }

    if (!notification.notificationType) {
      return;
    }

    switch (notification.notificationType) {
      case NotificationType.WeatherStationAlarm:
        return this.router.navigate(['weatherstations', notification.notificationEntityId, 'alarms']);
      case NotificationType.StorageSensorAlarm:
        return this.router.navigate(['storageSensors', notification.notificationEntityId, 'alarms']);
      case NotificationType.DiseaseAlarm:
        return this.router.navigate(['cultivation-assistant', notification.notificationEntityId, 'disease']);
      case NotificationType.PartnerRecomendation:
        return this.router.navigate(['cultivation-assistant', notification.notificationEntityId, 'informations']);
      case NotificationType.ObservationRecommendation:
        return this.router.navigate(['observations', notification.notificationEntityId, 'recommendations']);
      case NotificationType.FuelAnomaly:
        return this.router.navigate(['machines', notification.notificationEntityId, 'events']);
        return;
      default:
        throw new Error(`${notification.notificationType} is not supported`);
    }
  }
}
