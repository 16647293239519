<div class="input-timepicker form-group">
  <label
    *ngIf="labelTranslateKey.length > 0"
    class="label"
    [ngClass]="{ required: isInputControlRequired() }"
    [for]="labelTranslateKey">
    {{ labelTranslateKey | translate }}
  </label>
  <div class="input-group mb-0">
    <ngb-timepicker
      [formControl]="inputControl"
      [spinners]="false"></ngb-timepicker>
  </div>
  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
