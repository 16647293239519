<div class="input-datepicker form-group">
  <label
    *ngIf="labelTranslateKey.length > 0"
    class="label"
    [ngClass]="{ required: isInputControlRequired() }"
    [for]="labelTranslateKey">
    {{ labelTranslateKey | translate }}
  </label>
  <div class="input-group mb-0">
    <input
      type="text"
      class="form-control"
      placeholder="{{
        placeholderTranslateKey
          ? (placeholderTranslateKey | translate)
          : ('APPLICATION.FORM.enterPlaceholderText' | translate: { placeholder: labelTranslateKey | translate | lowercase })
      }}"
      [formControl]="inputControl"
      [id]="labelTranslateKey"
      [name]="labelTranslateKey"
      [maxDate]="maxDate"
      [ngClass]="{ 'is-invalid': isInputControlInvalid() }"
      ngbDatepicker
      #d1="ngbDatepicker" />
    <aside class="input-group-append">
      <button
        type="button"
        class="btn btn-secondary datepicker"
        (click)="d1.toggle()">
        <i class="ph ph-calendar-blank"></i>
      </button>
    </aside>
  </div>
  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
