<div class="form-group">
  <div
    id="original-map-container"
    class="map">
    <ng-container *ngIf="!isDisabled && mapOptions$ | async as mapOptions">
      <div
        class="btn-float-container"
        *ngIf="isDrawingEnabled()">
        <button
          type="button"
          [class.active]="isPolygonDrawingActive"
          class="btn btn-primary-outline btn-icon"
          (click)="setDrawingMode('polygon')"
          [disabled]="isPolygonLimitReached(mapOptions)"
          title="{{ 'APPLICATION.FORM.drawPolygon' | translate }}">
          <i class="ph ph-pencil-simple-line"></i>
        </button>

        <button
          type="button"
          [class.active]="isMarkerDrawingActive"
          class="btn btn-primary-outline btn-icon"
          (click)="setDrawingMode('marker')"
          [disabled]="isMarkersLimitReached(mapOptions)"
          title="{{ 'APPLICATION.FORM.drawMarker' | translate }}">
          <i class="ph ph-map-pin"></i>
        </button>
      </div>
    </ng-container>
    <app-pure-map
      [style.height]="height"
      [tilt]="0"
      (mapInitialized)="onMapInitialized($event)">
    </app-pure-map>
  </div>

  <ng-container *ngIf="ngControl">
    <app-validation-message
      [field]="ngControl"
      displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
  </ng-container>
</div>
