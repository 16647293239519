import { Component, forwardRef, Injector, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, NgControl, ValidationErrors, Validator } from '@angular/forms';
import { NgbDateStruct, NgbTimeStruct } from '@ng-bootstrap/ng-bootstrap';
import { parseISO } from 'date-fns';

@Component({
  selector: 'app-datetime-picker-control',
  templateUrl: './datetime-picker-control.component.html',
  styleUrls: [
    './../../input.base.scss',
    './../../input-timepicker/input-timepicker.component.scss',
    './datetime-picker-control.component.scss'
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerControlComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateTimePickerControlComponent),
      multi: true
    }
  ]
})
export class DateTimePickerControlComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() placeholder: string;
  @Input() set maxDate(date: Date) {
    if (date) {
      this.maxDateStruct = this.convertDateToNgbDateStruct(date);
    }
  }
  @Input() isInvalid: boolean;
  @Input() labelTranslateKey = '';
  @Input() allowSetTime: boolean;

  public maxDateStruct: NgbDateStruct;
  public isDisabled: boolean;

  public date: NgbDateStruct;
  public time: NgbTimeStruct = { hour: 0, minute: 0, second: 0 };

  private onChange: (value: Date | null | undefined) => void;
  private onTouched: () => void;
  ngControl: NgControl;

  constructor(private inj: Injector) {}

  ngOnInit() {
    this.ngControl = this.inj.get(NgControl);
  }

  writeValue(obj: Date | string | null | undefined): void {
    const date = this.getDate(obj);

    if (obj) {
      this.date = { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
      this.time = { hour: date.getHours(), minute: date.getMinutes(), second: date.getSeconds() };
    } else {
      this.date = null;
      this.time = { hour: 0, minute: 0, second: 0 };
    }
  }

  private getDate(obj: Date | string): Date | null | undefined {
    if (typeof obj === 'string') {
      return parseISO(obj);
    }

    return obj;
  }

  registerOnChange(fn: (value: Date) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onDateChange(date: NgbDateStruct | string) {
    if (typeof date === 'string') {
      const formControl = this.ngControl.control;
      formControl.errors['ngbDate'] = true;
      this.onTouched();
      return;
    }

    this.date = date;
    this.propagateChange();
  }

  onTimeChange(time: NgbTimeStruct) {
    this.time = time;
    this.propagateChange();
  }

  private propagateChange() {
    this.onChange(this.getValueToPropagate());
    this.onTouched();
  }

  private getValueToPropagate() {
    if (!this.date) {
      return null;
    }

    if (this.allowSetTime && !this.time) {
      return null;
    }

    if (this.allowSetTime) {
      return new Date(this.date.year, this.date.month - 1, this.date.day, this.time.hour, this.time.minute, this.time.second);
    }

    return new Date(this.date.year, this.date.month - 1, this.date.day, 0, 0, 0);
  }

  private convertDateToNgbDateStruct(date: Date): NgbDateStruct {
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  validate(): ValidationErrors | null {
    const errors: ValidationErrors = {};

    if (this.date) {
      const isValidDate = (date: NgbDateStruct): boolean => {
        const d = new Date(date.year, date.month - 1, date.day);
        return d.getFullYear() === date.year && d.getMonth() + 1 === date.month && d.getDate() === date.day;
      };

      if (!isValidDate(this.date)) {
        errors['ngbDate'] = true;
      } else if (this.maxDate) {
        const currentDate = new Date(this.date.year, this.date.month - 1, this.date.day);

        if (currentDate > this.maxDate) {
          errors['maxDate'] = {
            valid: false,
            maxDate: this.maxDate,
            actualDate: this.date
          };
        }
      }
    }

    return Object.keys(errors).length ? errors : null;
  }
}
