<div class="form-group mb-3">
  <quill-editor
    [class]="'editor-container'"
    [formControl]="inputControl"
    [modules]="editorModules"
    [styles]="{ height: '400px', width: '100%' }"></quill-editor>
  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
