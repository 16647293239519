<div class="input-select form-group">
  <label
    *ngIf="labelTranslateKey.length > 0"
    class="label"
    [ngClass]="{ required: isInputControlRequired() }"
    [for]="labelTranslateKey">
    {{ labelTranslateKey | translate }}
  </label>
  <select
    class="form-control form-select"
    [formControl]="inputControl"
    [id]="labelTranslateKey"
    [name]="labelTranslateKey"
    [ngClass]="{ 'is-invalid': isInputControlInvalid() }">
    <option
      *ngFor="let option of options"
      [ngValue]="option.value">
      {{ option.label | translate }}
    </option>
  </select>
  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
