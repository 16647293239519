import { Component, Input } from '@angular/core';

import { InputBaseComponent } from '../input.base';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./../input.base.scss']
})
export class InputTextComponent extends InputBaseComponent {
  @Input() public textMaskPattern: Array<string | RegExp>;
  @Input() IsReadOnly = false;
  @Input() public tooltipTranslateKey: string | null = null;
}
