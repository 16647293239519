<div class="form-group">
  <div class="custom-control custom-checkbox">
    <input
      type="checkbox"
      class="custom-control-input"
      [formControl]="inputControl"
      [id]="checkboxId !== null ? checkboxId : labelTranslateKey"
      [name]="labelTranslateKey" />
    <label
      *ngIf="labelTranslateKey.length > 0"
      class="label custom-control-label"
      [ngClass]="{ required: isInputControlRequired() }"
      [for]="checkboxId !== null ? checkboxId : labelTranslateKey"
      [innerHTML]="labelHtml ? labelHtml : (labelTranslateKey | translate)"></label>
    <label
      *ngIf="isInputControlRequired()"
      class="checkbox-required">
      *
    </label>
  </div>
  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
