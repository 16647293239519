<div class="input-datepicker form-group">
  <label
    *ngIf="labelTranslateKey.length > 0"
    class="label"
    [ngClass]="{ required: isInputControlRequired() }"
    [for]="labelTranslateKey">
    {{ labelTranslateKey | translate }}
  </label>

  <app-datetime-picker-control
    [formControl]="inputControl"
    [maxDate]="maxDate"
    [labelTranslateKey]="labelTranslateKey"
    [isInvalid]="isInputControlInvalid()"
    [allowSetTime]="allowsSetTime"
    placeholder="{{
      placeholderTranslateKey
        ? (placeholderTranslateKey | translate)
        : ('APPLICATION.FORM.enterPlaceholderText' | translate: { placeholder: labelTranslateKey | translate | lowercase })
    }}"></app-datetime-picker-control>

  <app-validation-message
    [field]="inputControl"
    displayName="{{ labelTranslateKey | translate | lowercase }}"></app-validation-message>
</div>
