<ng-container *ngIf="getEmptyStateDisplayParams().shouldDisplayEmptyState; else content">
  <div
    class="empty-state py-2"
    *ngIf="getEmptyStateDisplayParams().shouldDisplayEmptyStateContent">
    <img
      [src]="'./assets/images/' + assetSrc"
      alt="Empty State"
      class="empty-state-img" />
    <h5 class="empty-state-header">{{ title }}</h5>
    <div
      class="empty-state-content mb-3"
      [innerHTML]="subtitle"></div>
    <button
      *ngIf="shouldShowButton()"
      (click)="onClick()"
      class="btn btn-primary">
      <span>{{ btnText }}</span>
      <i class="{{ btnIcon }}"></i>
    </button>
  </div>
</ng-container>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
